import React, { useState, useEffect, useRef } from 'react';
import "./App.css";
import { Grid, Card, Stack, Typography, Link, Button, Modal, Box, Checkbox, FormControlLabel, Select, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import prettyms from 'pretty-ms';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ErrorIcon from '@mui/icons-material/Error';
import RunCircleIcon from '@mui/icons-material/RunCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BuildIcon from '@mui/icons-material/Build';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import JsonEditor from './JsonEditor';

const OrchestratorUrl = "https://api.scalhero.com";
//const OrchestratorUrl = "https://localhost:7131";

function App() {
  const setSuspend = async () => await fetch(OrchestratorUrl + '/?service=%2A&action=agentsuspend', { method: 'put'});
  const setResume =  async () => await fetch(OrchestratorUrl + '/?service=%2A&action=agentresume', { method: 'put'});
  const shutdownAgent =  async (agent) => await fetch(OrchestratorUrl + '/agent/'+ agent.agentUId + '/shutdown', { method: 'get'});
  const updateAgent =  async (agent) => await fetch(OrchestratorUrl + '/agent/'+ agent.agentUId + '/update', { method: 'get'});
  
  const [data, setData] = useState(null);
  const [jobLogOpen, setJobLogOpen] = useState(false);
  const [jobLog, setJobLog] = useState([]);
  const [filteredJobLog, setFilteredJobLog] = useState([]);
  const [jobFilter, setJobFilter] = useState(["Error", "Warning", "Information"]);
  
  const [instanceDetailOpen, setinstanceDetailOpen] = useState(false);
  const [instanceDetail, setInstanceDetail] = useState({});
  const [instanceDetailJobs, setInstanceDetailJobs] = useState([]);
  const [instanceDetailSelectedJob, setInstanceDetailSelectedJob] = useState({});

  // Refs for async updates
  const instanceDetailOpenRef = useRef(instanceDetailOpen);
  instanceDetailOpenRef.current = instanceDetailOpen;
  const jobLogOpenRef = useRef(jobLogOpen);
  jobLogOpenRef.current = jobLogOpen;

  const [tempInstanceConfiguration, setTempInstanceConfiguration] = useState({});
  const [tempJobConfiguration, setTempJobConfiguration] = useState({});
  const [updatedInstanceConfiguration, setUpdatedInstanceConfiguration] = useState({});
  const [updatedJobConfiguration, setUpdatedJobConfiguration] = useState({});
  
  
  useEffect(() =>{
    const fetchData = async () => {
      try
      {
        if(!instanceDetailOpenRef.current && !jobLogOpenRef.current){
          const result = await fetch(OrchestratorUrl + "/agent/status");
          const rJson = await result.json();
          rJson.jobs.sort((a, b) => (new Date(b.updateDate) - new Date(a.updateDate) ));
          for (let index = 0; index < rJson.jobs.length; index++) {
            const element = rJson.jobs[index];
            // console.dir(element.jobUId + " => " + element.updateDate);
          }
          setData(rJson);
        }
      }catch{
      }
      setTimeout(() => { fetchData(); }, 5000);
    };
    fetchData();
  }, []);

  // Instance
  async function fetchJobDetail(job)
  {
    await fetchInstanceDetail(job.user, job.workloadType, job.instanceUId);
  }

  async function fetchInstanceDetail(user, workloadType, instanceUId)
  {
    const result = await fetch(OrchestratorUrl + "/" + user + "/workloadInstance/" + workloadType + "/instance/" + instanceUId);
    const rJson = await result.json();
    setInstanceDetail(rJson[0]);
    setTempInstanceConfiguration(JSON.parse(rJson[0].configuration));
    setUpdatedInstanceConfiguration(JSON.parse(rJson[0].configuration));
    

    const resultJobs = await fetch(OrchestratorUrl + "/" + user + "/workloadInstance/" + workloadType + "/instance/" + instanceUId + "/job/available");
    const rJsonJobs = await resultJobs.json();    
    if(rJsonJobs.length>0){
      setInstanceDetailSelectedJob(rJsonJobs[0]);
      setInstanceDetailJobs(rJsonJobs);
      setTempJobConfiguration(JSON.parse(rJsonJobs[0].configuration));
      setUpdatedJobConfiguration(JSON.parse(rJsonJobs[0].configuration));
    }

    setinstanceDetailOpen(true);
  }  

  async function changeInstanceDetailState(targetValue){
    var newState = Object.assign({}, instanceDetail);
    newState.state = targetValue;
    setInstanceDetail(newState);
  }

  async function saveInstanceDetail(){
    await fetch(
      OrchestratorUrl + "/" + instanceDetail.user + "/workloadInstance/" + instanceDetail.workloadType + "/instance/" + instanceDetail.instanceUId + "?state="+ instanceDetail.state + "&configuration=" + JSON.stringify(updatedInstanceConfiguration), 
      { method: 'put' });

    fetchInstanceDetail(instanceDetail.user, instanceDetail.workloadType, instanceDetail.instanceUId);
  }

  // Jobs
  function selectJob(value){
    var job = instanceDetailJobs.filter(j=>j.jobType === value)[0];
    setInstanceDetailSelectedJob(job);
    setTempJobConfiguration(JSON.parse(job.configuration));
  }

  async function runJob(){
    await fetch(
      OrchestratorUrl + "/" + instanceDetail.user + "/workloadInstance/" + instanceDetail.workloadType + "/instance/" + instanceDetail.instanceUId + "/job", 
        { 
          method: 'put', 
          body: JSON.stringify(
            {
              jobType: instanceDetailSelectedJob.jobType, 
              configuration: JSON.stringify(updatedJobConfiguration) 
            }
          ),          
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      );
  }

  // Logs
  async function fetchJobLog(jobUId)
  {
    const result = await fetch(OrchestratorUrl + "/agent/job/" + jobUId);
    const rJson = await result.json();
    setJobLog(rJson);
    setFilteredJobLog(rJson.filter(r=>jobFilter.indexOf(r.status)!==-1 || r.eventType!=='output'));
    setJobLogOpen(true);
  }

  function toggleFilter(filter, checked){
    const index = jobFilter.indexOf(filter);
    if(index >-1 && !checked){
      jobFilter.splice(index, 1);
    }
    if(index === -1 && checked){
      jobFilter[jobFilter.length] = filter;
    }
    setJobFilter(jobFilter);
    setFilteredJobLog(jobLog.filter(r=>jobFilter.indexOf(r.status)!==-1 || r.eventType!=='output'));
  }

  const jobStatuses = [
    { value : 'done', label: 'done' },
    { value : 'error', label: 'error' },
    { value : 'Draft', label: 'Draft' },
    { value : 'Building', label: 'Building' },
    { value : 'Publishing', label: 'Publishing' },
    { value : 'Running', label: 'Running' },
    { value : 'Deleting', label: 'Deleting' },
    { value : 'Deploying', label: 'Deploying' },
  ];

  function getJobStatusIcon(status){
    switch (status) {
      case 'done': return <AssignmentTurnedInIcon color={'success'} fontSize='small' />;
      case 'error': return <ErrorIcon color={'error'} fontSize='small' />;
      case 'Draft': return <DesignServicesIcon color={'warning'} fontSize='small' />;
      case 'Deleting': return <DeleteForeverIcon color={'error'} fontSize='small' />;
      case 'Running': return <RunCircleIcon color={'success'} fontSize='small' />;
      case 'Deploying': return <CloudUploadIcon color={'success'} fontSize='small' />;
      case 'Building': return <BuildIcon color={'success'} fontSize='small' />;
      case 'Publishing': return <PublishedWithChangesIcon color={'success'} fontSize='small' />;
      default: return <></>;
    }
  }

  function getJobLogClass(jobStatus)
  {
    switch (jobStatus) {
      case "Verbose":
      case "Warning":
      case "Error":
      case "Debug":
      case "Information":
        return "jobLogType" + jobStatus      
      default:
        return "jobLogTypeUnknown"
    }
  }

  function getAgentLabel(agent){
    if(agent.jobUId)
      return agent.jobUId + " · " + agent.instanceUId?.substring(0,10);
    else
      return "No Ongoing Job";
  }

  function getAgentStatus(status){
    var toReturn = status;
    if(toReturn?.startsWith("Agent")) toReturn = toReturn.slice(5);
    return toReturn;
  }

  function getVersion(version){
    return version?.substring(0,version.indexOf("+"));
  }

  return (
      <div className="App">
        <div className="App-Header">
          
          <Modal
            open={instanceDetailOpen}
            onClose={()=>setinstanceDetailOpen(false)}
          >
            <Box className='modalContainer'>
              <Grid className='modalHeader'>
                <Typography className='modalTitle'>
                  {instanceDetail.instanceUId} 
                  <span className='modalSubTitle'>{instanceDetail.workloadType}</span>
                </Typography>
              </Grid>
                            
              <Grid container>
                <Grid container item xs={6} className='modalContentSplit'>
                  <Grid item padding={1} alignSelf={'center'}>
                    Status:
                  </Grid> 
                  <Grid item padding={1}>
                    <Select 
                      value={instanceDetail.state} 
                      onChange={(e)=> changeInstanceDetailState(e.target.value) }>
                      {jobStatuses.map(s => 
                        <MenuItem key={s.value} value={s.value}>
                          <Box sx={{ flexDirection: 'row', display: 'flex', }}>
                            <ListItemIcon className='selectIcon'>{getJobStatusIcon(s.value)}</ListItemIcon>
                            <ListItemText>{s.label}</ListItemText>
                          </Box>
                        </MenuItem>
                      )}
                    </Select>
                  </Grid> 
                  <Grid item padding={1}>
                    <Button onClick={()=>saveInstanceDetail()}>Save</Button>
                  </Grid>
                </Grid>
                <Grid container item xs={6} className='modalContentSplit'>
                  <Grid item padding={1} alignSelf={'center'}>
                    Available Jobs:
                  </Grid>
                  <Grid item padding={1}>
                    <Select 
                      value={(instanceDetailSelectedJob?.jobType)??''}
                      onChange={(e)=> selectJob(e.target.value) }>
                      {instanceDetailJobs.map(s => 
                        <MenuItem key={s.jobType} value={s.jobType}>
                          <ListItemText>{s.jobType}</ListItemText>
                        </MenuItem>
                      )}
                    </Select>
                  </Grid>
                  <Grid item padding={1}>
                    <Button onClick={()=>runJob()}>Run Job</Button>
                  </Grid>
                </Grid>
              </Grid>              
              <Grid container className='modalContent modalContentSplitContainer'>
                <Grid paddingRight container item xs={6} className='modalContentSplitContent'>
                  {instanceDetail?.configuration && 
                    <JsonEditor
                      value={tempInstanceConfiguration}
                      onChange={(data)=> setUpdatedInstanceConfiguration(data)}
                      htmlElementProps={{style: {height: '100%'}}}
                    />
                  }
                </Grid>
                <Grid paddingLeft container item xs={6} className='modalContentSplitContent'>
                  {instanceDetailSelectedJob?.configuration &&
                    <JsonEditor
                      value={tempJobConfiguration}
                      onChange={(data)=>setUpdatedJobConfiguration(data)}
                      htmlElementProps={{style: {height: '100%'}}}
                    />
                  }
                </Grid>

              </Grid>
            </Box>
          </Modal>

          <Modal
            open={jobLogOpen}
            onClose={()=>setJobLogOpen(false)}
          >
            <Box className='modalContainer'>
              <Typography>
                Job Log 
                <FormControlLabel control={<Checkbox onClick={(e)=>toggleFilter("Error", e.target.checked)}/>} label="Error" checked={jobFilter.indexOf("Error")!==-1} />
                <FormControlLabel control={<Checkbox onClick={(e)=>toggleFilter("Warning", e.target.checked)}/>} label="Warning" checked={jobFilter.indexOf("Warning")!==-1}/>
                <FormControlLabel control={<Checkbox onClick={(e)=>toggleFilter("Information", e.target.checked)}/>} label="Information" checked={jobFilter.indexOf("Information")!==-1}/>
                <FormControlLabel control={<Checkbox onClick={(e)=>toggleFilter("Debug", e.target.checked)}/>} label="Debug" checked={jobFilter.indexOf("Debug")!==-1}/>
                <FormControlLabel control={<Checkbox onClick={(e)=>toggleFilter("Verbose", e.target.checked)}/>} label="Verbose" checked={jobFilter.indexOf("Verbose")!==-1}/>
                <FormControlLabel control={<Checkbox onClick={(e)=>toggleFilter("Progress", e.target.checked)}/>} label="Progress" checked={jobFilter.indexOf("Progress")!==-1}/>
              </Typography>              
              <Grid container className='modalContent scroll'>
                {filteredJobLog.map((job, index) => { return (
                  <Grid item container key={index} xs={12} className={getJobLogClass(job.status)}>
                    <Grid item xs={1} className='jobLogContentTimestamp'>{prettyms(new Date(job.tickDate) - new Date(filteredJobLog[0].tickDate), { unitCount: 2 })}</Grid>
                    <Grid item xs={11}>{job.message}</Grid>
                  </Grid>
                )})}
              </Grid>
            </Box>
          </Modal>

          {data && 
            <Grid container spacing={2} padding={2}>
              <Grid item xs={7}>
                <div className='Left-Title'>
                  <img src="logo.svg" height={125}/>
                </div>                
              </Grid>
              <Grid item xs={2}>
                {
                  data.job?.agentDequeuePaused
                  ?<Card className='App-Header-BigStat-Container' data-state='suspended'><Button onClick={()=>setResume()}>Suspended</Button></Card>
                  :<Card className='App-Header-BigStat-Container' data-state='running'><Button onClick={()=>setSuspend()}>Running</Button></Card>
                }
                <Button onClick={() => fetchInstanceDetail('hello@byrnu.com', 'umbraco', 'byrnu') }>Byrnu</Button>
              </Grid>
              <Grid item xs={1}>
                <Card className='App-Header-BigStat-Container'>
                  <div className='App-Header-BigStat'>{data.job.queuedJobs}</div>
                  Queued
                </Card>
              </Grid>
              <Grid item  xs={1}>
                <Card className='App-Header-BigStat-Container' xs={3}>
                  <div className='App-Header-BigStat'>{data.job.runningJobs}</div>
                  Running
                </Card>
              </Grid>
              <Grid item  xs={1}>
                <Card className='App-Header-BigStat-Container' xs={3}>
                  <div className='App-Header-BigStat'>{data.job.orphanJobs}</div>
                  Orphan
                </Card>              
              </Grid>
            </Grid>}
        </div>
        <Grid container className='App-Body'>
            <Grid item xs={3}>
              {data && data.jobs && data.jobs.map(job => { return (
                <Grid className='jobCardContainer' container key={job.jobUId}>
                  <Card className='jobCard' item xs={12}>
                    <Grid container>
                      <Grid className='jobHeaderContainer' container item xs={12}>
                        <div item xs={6} className="jobHeader"
                              data-status={job.status} 
                              data-running={Date.now() - new Date(job.tickDate) < 180000}>
                          <Link className='jobHeaderAgentUId' target="_blank" href={OrchestratorUrl + "/agent/status/" + job.agentUId}>{job.agentUId.slice(job.agentUId.length - 5)}</Link>
                        </div>
                        <div item xs={6} className="jobHeader">
                          <Link className='jobHeaderJobUId' target="_blank" onClick={() => { fetchJobLog(job.jobUId); }}>{job.jobUId}</Link>
                        </div>
                        <div item xs={4} className="jobHeader">
                          <Link className='jobHeaderInstanceUId' target="_blank" onClick={() => { fetchJobDetail(job); }}>{job.instanceUId}</Link>                          
                        </div>
                      </Grid>
                      <Grid item xs={12} className='jobMessage'>
                        {job.message}
                      </Grid>
                      
                      <Grid item xs={12} className='jobStatus'>
                        
                      </Grid>
                      <Grid className='jobFooterContainer' container item xs={12}>
                        <div item xs={6} className="jobFooter">
                          {job.status}
                        </div>
                        <div item xs={6} className="jobFooter">
                          Last seen {prettyms(Date.now() - new Date(job.tickDate), { unitCount: 2 })} ago
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              )})}
            </Grid>
            <Grid container item xs={9}>
              {!data ? "Loading..." : <Grid container spacing={2} padding={2}>
                {data.agents.map(agent => { return (
                    <Grid key={agent.agentUId} item xs={4}>
                      <Card>
                        <Grid className='AgentCard' container>
                          <Grid item xs={2}> 
                            <div className="corner agent"
                                  data-status={agent.agentStatus} 
                                  data-running={Date.now() - new Date(agent.jobTickDate) < 180000}
                                  data-live={Date.now() - new Date(agent.lastSeen) < 10000}>
                              <Link target="_blank" href={OrchestratorUrl + "/agent/status/" + agent.agentUId}>{agent.agentUId.slice(agent.agentUId.length - 5)}</Link>
                            </div>
                            <div className='corner version'>
                              <Button className='cornerButton' onClick={() => updateAgent(agent)}>Update</Button>
                              <Button onClick={() => shutdownAgent(agent)}>Shutdown</Button>
                              <label>{getVersion(agent.agentVersion)}</label>
                            </div>
                          </Grid>
                          <Grid item xs={7}>
                            <Stack direction={'column'} padding={'3pt'}>
                              <Stack direction={'row'} alignItems={'left'}>
                                <Link className='jobId' target="_blank" href={OrchestratorUrl + "/agent/jobstatus?jobuid=" + agent.jobUId}>{getAgentLabel(agent)}</Link> 
                              </Stack>
                              <Stack alignItems={'left'}>
                                {agent.jobStatus?<Typography align='left' fontSize={'x-small'}>{agent.jobStatus}: {agent.jobMessage?.substring(0,30)}</Typography>:""}
                                {agent.creationDate && agent.agentStatus==='running'?<Typography align='left' fontSize={'x-small'}>Since {prettyms(Date.now() - new Date(agent.creationDate), { unitCount: 2 })}</Typography>:""}
                                {agent.JobTickDate && agent.agentStatus!=='running'?<Typography align='left' fontSize={'x-small'}>Last job  {prettyms(Date.now() - new Date(agent.jobTickDate), { unitCount: 2 })} ago</Typography>:""}
                              </Stack>
                              <Stack direction={'row'} alignItems={'left'}>
                                <div className='AgentStatus'>{getAgentStatus(agent.agentStatus)}</div>
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid item xs={1} alignContent={'center'} alignItems={'center'} display={'flex'}>
                            {getJobStatusIcon(agent.jobStatus)}
                          </Grid>
                          <Grid item xs={2}> 
                            <div className="corner latency">
                              <label>Latency</label>
                              <div>{prettyms(agent.latency??0, {compact: true})}</div>
                            </div>
                            <div className="corner lastseen">
                              <label>Last Seen</label>
                              {prettyms(Date.now() - new Date(agent.lastSeen), {compact: true})}                              
                            </div>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  )})}
              </Grid>}
            </Grid>
      </Grid>
    </div>
  );
}

export default App;